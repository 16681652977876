import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function CallBack(){
    return (
      <section className="mid-contact">
        <Container>
          <Row>
            <Col xl={8} lg={12} className="mx-auto">
              <div className="web-heading">
                <h1>
                  <span>Get More Traffic, More Leads, And Sales,</span>
                </h1>
                <p>
                  Our expert team members click the nerve of the audience and
                  create the brand awareness your business deserves to generate
                  more traffic, leads and sales. We stay brand-specific and
                  boost your visibility by organic means.
                </p>
              </div>
              <ul className="d-md-flex">
                <li>
                  <a href="mailto:csc@digitalcybersolution.co.in" className="btn btn-light">
                    <i className="fa fa-message" /> Mail us
                  </a>
                </li>
                <li>
                  <p>or</p>
                </li>
                <li>
                  <a href="tel:9911780174" className="btn btn-outline-light"> <i className="fa fa-phone" />Call us
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    );
}
export default CallBack;