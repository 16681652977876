import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function TrustedCounter() {
  const TrustedData = [
    {
      id: 1,
      NumberValue: "11M+",
      TitleName: "Business Customers",
      className: "text-danger",
    },
    {
      id: 2,
      NumberValue: "840K+",
      TitleName: "Satisfied Clients",
      className: "text-warning",
    },
    {
      id: 3,
      NumberValue: "11M+",
      TitleName: "Productive Users",
      className: "text-info",
    },
    {
      id: 4,
      NumberValue: "35K+",
      TitleName: "Projects succeeded",
      className: "text-success",
    },
  ];

  return (
    <Container>
      <div className="trusted-counter">
        <Row>
          <Col xxl={8} xl={9} lg={10} className="mx-auto my-5">
            <div className="web-heading">
              <h1>
                Trusted by <span> 7500+</span> users and even more employees
              </h1>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
                porro quam aliquid et magnam dolorem officiis! Illo neque ab
                voluptatem aspernatur accusantium minima eos!
              </p>
            </div>
            <Row>
              {TrustedData.map((value, index) => {
                return (
                  <Col lg={3} key={index}>
                    <div className="counter">
                      <h3 className={value.className}>{value.NumberValue}</h3>
                      <p>{value.TitleName}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
export default TrustedCounter;
