import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Table } from "react-bootstrap";

const Dashboard = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://digitalcybersolution.co.in/api/leads.php"
        );
        const responseData = response.data.data;
        setData(responseData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData(); // Call the fetchData function when the component mounts
  }, []); // Empty dependency array ensures the effect runs only once

  const ApiUrl = "https://digitalcybersolution.co.in/api/uploads/";

  return (
    <Container>
      <div className="app-table">
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Service</th>
              <th>Document</th>
              <th>Message</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map((rowData) => (
                <tr key={rowData.id}>
                  <td>{rowData.id}</td>
                  <td>{rowData.fname}</td>
                  <td>{rowData.lname}</td>
                  <td className="text-lowercase">{rowData.email}</td>
                  <td>{rowData.mobile}</td>
                  <td>{rowData.service}</td>
                  <td>
                    <a
                      href={ApiUrl + rowData.document}
                      target="_blank"
                    >
                      View
                    </a>
                  </td>
                  <td>{rowData.message}</td>
                  <td>{rowData.created_at}</td>
                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td colSpan="9">No records to found</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </Container>
  );
};

export default Dashboard;
