import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function ThankYou() {
  return (
    <section className="thank-you">
      <Container>
        <Row>
          <Col xxl={6} className="mx-auto">
            <div className="web-heading">
              <img src="assets/img/yes.png" />
              <h1>Thank You!</h1>
              <p>For your interest</p>
              <p>
                We are delighted to see your response. Our team is excited to
                work with you! We will get back to you soon. If your requirement
                is really, really urgent, you can directly contact us through
                this number.{" "}
                <a href="tel:9911780174" target="_blank">
                  +91 9911780174
                </a>
              </p>
              <Link to="/" className="w-auto btn btn-primary cursor-pointer">
                Go back to homepage
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
export default ThankYou;
