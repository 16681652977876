import React, { useEffect, useState } from "react";
import ContactUs from "../components/shared/ContactUs";
import { useParams } from "react-router-dom";
import ServiceData from '../ourService.json';

function DynamicContactUs() {
  const [filteredData,setFilteredData] = useState();
   const ParamsName = useParams().id
   useEffect(()=>{
    const foundObject = ServiceData.find(item => item.ServiceTitle === ParamsName);
    console.log(foundObject,"foundObject");
    setFilteredData(foundObject.CompanyRegistration);
   },[ParamsName])

   console.log(filteredData,"filteredData");
  return (
    <React.Fragment>
      <nav aria-label="breadcrumb" className="pt-5 mt-4 mx-4">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active" className="fw-bold" aria-current="page">
            {ParamsName&&ParamsName}
          </li>
        </ol>
        <ul>
          {
            filteredData&&filteredData.map((value,index)=>{
              return(
                <li key={index}>{value.RequiredField}</li>
              )
            })
          }
        </ul>
      </nav>
      <ContactUs />
    </React.Fragment>
  );
}
export default DynamicContactUs;
