import React, { useState, useRef } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import axios from "axios";
import { useNavigate,useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import serviceData from "../../serviceData.json";
import Select from "react-select";

function ContactUs() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [formDataResponse, setFormDataResponse] = useState({
    fname: "",
    lname: "",
    email: "",
    mobile: "",
    service: "",
    document: null, // Store the file object
    message: "",
    action: "leads",
  });

  const formRef = useRef();

  const navigate = useNavigate();
  const NameSelectedValue = useParams().id;

  const handleChange = (e) => {
    const { id, value, type, files } = e.target;

    // Check if it's a file input
    if (type === "file") {
      setFormDataResponse((prevData) => ({
        ...prevData,
        [id]: files[0], // Store the file object
      }));
    } else {
      setFormDataResponse((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    for (const key in formDataResponse) {
      formData.append(key, formDataResponse[key]);
    }

    const apiUrl = "https://digitalcybersolution.co.in/api/leads.php";

    axios
      .post(apiUrl, formData)
      .then((response) => {
        if (response.status === 200) {
          toast(response.data.data);
          setFormDataResponse({
            fname: "",
            lname: "",
            email: "",
            mobile: "",
            service: "",
            document: "", // Store the file object
            message: "",
            action: "leads",
          });
          formRef.current.reset();
          navigate("/thankyou");
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);

    // Assuming you have a 'value' property in your serviceData options
    const selectedServiceId = selectedOption.value;
    console.log(selectedServiceId,"selectedServiceId");
    // Redirect to another route with the selected service ID
    navigate(`/service/${selectedServiceId}`);
  };

  return (
    <section className="contact-us">
      <Container>
        <div className="web-heading">
          <h1>Get Your Free Consultation</h1>
          <p>
            Share your business needs or project requirements with us &amp; we
            will get back to you quickly.
          </p>
        </div>
        <Row>
          <Col xl={10} className="mx-auto">
            <div className="bg-white shadow">
              <Row className="g-0">
                <Col xl={5} lg={5} className="d-flex">
                  <div className="contact-left">
                    <h4>Contact Details</h4>
                    <p>
                      Fill up the form and our Team will get back to you within
                      24 hours.
                    </p>
                    <ul>
                      <li>
                        <i className="fa fa-phone" />
                        <a href="tel:9911780174">+91 9911780174</a>
                      </li>
                      <li>
                        <i className="fa fa-envelope" />
                        <a href="mailto:csc@digitalcybersolution.co.in">
                          csc@digitalcybersolution.co.in
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-location-dot" />
                        <p>
                          RC 528, Vandana Vihar, Khora Colony, <br />
                          Ghaziabad, Uttar Pradesh - 201301
                        </p>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xl={7} lg={7}>
                  <Form
                    ref={formRef}
                    className="contact-right"
                    onSubmit={handleSubmit}
                  >
                    <Row>
                      <Col md={6} xs={6} className="mb-3">
                        <Form.Label htmlFor="fname">
                          First Name <span>*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="fname"
                          value={formDataResponse.fname}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                      <Col md={6} xs={6} className="mb-3">
                        <Form.Label htmlFor="lname">
                          Last Name <span>*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="lname"
                          value={formDataResponse.lname}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                      <Col md={6} xs={6} className="mb-3">
                        <Form.Label htmlFor="email">
                          Email <span>*</span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          id="email"
                          value={formDataResponse.email}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                      <Col md={6} xs={6} className="mb-3">
                        <Form.Label htmlFor="number">
                          Number <span>*</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          id="mobile"
                          min={0}
                          value={formDataResponse.mobile}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                      <Col md={6} xs={6} className="mb-3">
                        <Form.Label htmlFor="serviceName">
                          Service Name <span>*</span>
                        </Form.Label>
                        <Select
                          defaultValue={selectedOption}
                          onChange={handleSelectChange}
                          options={serviceData}
                        />
                      </Col>
                      <Col md={6} xs={6} className="mb-3">
                        <Form.Label htmlFor="document">
                          Document <span>*</span>
                        </Form.Label>
                        <Form.Control
                          type="file"
                          id="document"
                          name="document"
                          onChange={handleChange}
                          required
                        />
                      </Col>
                      <Col lg={12} className="mb-3">
                        <Form.Label htmlFor="message">
                          Message <span>*</span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          id="message"
                          value={formDataResponse.message}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                      <div className="col-lg-12 text-end">
                        <Button variant="primary" type="submit">
                          Submit
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {/* <ToastContainer /> */}
      </Container>
    </section>
  );
}
export default ContactUs;
