import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import OurSeriveCard from '../../ourService.json'

function OurServices() {
  return (
    <section className="our-services">
      <Container>
        <div className="web-heading">
          <h1>our digital services</h1>
          <p>
          Elevating Your Digital Landscape with Our Cutting-edge Services
          </p>
        </div>
        <Row className="g-xxl-5 g-xl-5 g-lg-5 g-md-5 gy-5 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1">
          {OurSeriveCard.map((value, index) => {
            return (
              <Col className="d-flex" key={index}>
                <Card>
                  <Card.Body>
                    <img src={value.src} alt="loading..." />
                    <Link to={`/service/${value.ServiceTitle}`} className="stretched-link">
                      <h5>{value.ServiceTitle}</h5>
                    </Link>
                    <p>{value.ServiceSubTitle}</p>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
}
export default OurServices;
