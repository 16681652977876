import React from "react";
import ContactUs from "../components/shared/ContactUs";

function Contactus() {
  return (
    <React.Fragment>
      <ContactUs />
    </React.Fragment>
  );
}
export default Contactus;
