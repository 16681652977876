import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function Footer() {
  return (
    // <footer className="footer">
    //   <Container>
    //     <Row>
    //       <Col xxl={4} xl={4} lg={4} md={6} className="my-3">
    //         <div className="footer-link">
    //           <h6>LOCATIONS</h6>
    //           <ul>
    //             <li>
    //               <p className="fs-6 fw-600 mb-3">
    //                Logo
    //               </p>
    //             </li>
    //             <li>
    //               <p className="fs-6 fw-600 mb-3">
    //                 <img
    //                   src="assets/img/india.png"
    //                   alt="india"
    //                   className="me-2"
    //                 />
    //                 Ghaziabad - Corporate Office
    //               </p>
    //               <p>
    //                   RC 528, Vandana Vihar, Khora Colony, Ghaziabad, Uttar Pradesh - 201301
    //               </p>
    //             </li>
    //           </ul>
    //         </div>
    //       </Col>
    //       <Col xxl={2} xl={2} lg={2} md={3} className="my-3">
    //         <div className="footer-link">
    //           <h6>our service</h6>
    //           <ul>
    //             <li>
    //               <a href="#">Payroll</a>
    //             </li>
    //             <li>
    //               <a href="#">TDS</a>
    //             </li>
    //             <li>
    //               <a href="#">ESS</a>
    //             </li>
    //             <li>
    //               <a href="#">Assets management</a>
    //             </li>
    //             <li>
    //               <a href="#">masters</a>
    //             </li>
    //           </ul>
    //         </div>
    //       </Col>
    //       <Col xxl={2} xl={2} lg={2} md={3} className="my-3">
    //         <div className="footer-link">
    //           <h6>About Us</h6>
    //           <ul>
    //             <li>
    //               <a href="#">Contact Us</a>
    //             </li>
    //             <li>
    //               <a href="#">About Us</a>
    //             </li>
    //             <li>
    //               <a href="#">Payroll</a>
    //             </li>
    //             <li>
    //               <a href="#">Payroll</a>
    //             </li>
    //             <li>
    //               <a href="#">Payroll</a>
    //             </li>
    //           </ul>
    //         </div>
    //       </Col>
    //       <Col xxl={2} xl={2} lg={2} md={3} className="my-3">
    //         <div className="footer-link">
    //           <h6>QUICK LINKS</h6>
    //           <ul>
    //             <li>
    //               <a href="#">Contact Us</a>
    //             </li>
    //             <li>
    //               <a href="#">About Us</a>
    //             </li>
    //             <li>
    //               <a href="#">Terms</a>
    //             </li>
    //             <li>
    //               <a href="#">Privacy Policy</a>
    //             </li>
    //           </ul>
    //         </div>
    //       </Col>
    //       <Col xxl={2} xl={2} lg={2} md={3} className="my-3">
    //         <div className="footer-link">
    //           <h6>CONNECT us</h6>
    //           <div className="social-links">
    //             <ul>
    //               <li>
    //                 <a href="#" target="_blank">
    //                   <img src="assets/img/instagram.svg" alt="Instagram" />
    //                   <p>Instagram</p>
    //                 </a>
    //               </li>
    //               <li>
    //                 <a href="#" target="_blank">
    //                   <img src="assets/img/facebook.svg" alt="facebook" />
    //                   <p>facebook</p>
    //                 </a>
    //               </li>
    //               <li>
    //                 <a href="#" target="_blank">
    //                   <img src="assets/img/twitter.svg" alt="twitter" />
    //                   <p>twitter</p>
    //                 </a>
    //               </li>
    //               <li>
    //                 <a href="#" target="_blank">
    //                   <img src="assets/img/linkedin.svg" alt="linkedin" />
    //                   <p>linkedin</p>
    //                 </a>
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //       </Col>
    //     </Row>
    //     <hr className="border" />
    //     <p className="mb-0 text-secondary fs-14">
    //       Copyright © Digital Cyber Solution All Rights
    //       Reserved
    //     </p>
    //   </Container>
    // </footer>
    <footer className="mt-5">
      <div className="upper-footer">
        <Container>
          <Row>
            <Col xxl={4} xl={4} lg={4} md={6} className="my-3">
              <h4>Website Digital Cyber Solution</h4>
              <img src="assets/img/logo-1.png" />
            </Col>
            <Col xxl={4} xl={4} lg={4} md={6} className="my-3">
              <h5>Contact</h5>
              <ul>
                <li>
                  <div>
                    <svg
                      aria-hidden="true"
                      class="e-font-icon-svg e-fas-map-marker-alt"
                      viewBox="0 0 384 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                    </svg>
                  </div>
                  <div>
                    <p>
                      Vandana Vihar Khora Colony Ghaziabad Uttar Pradesh 201001
                      India
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <svg
                      aria-hidden="true"
                      class="e-font-icon-svg e-fas-phone-alt"
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
                    </svg>
                  </div>
                  <div>
                    <p>+91 9911780174</p>
                  </div>
                </li>
                <li>
                  <div>
                    <i className="fa fa-envelope"></i>
                  </div>
                  <div>
                    <p>csc@digitalcybersolution.co.in</p>
                  </div>
                </li>
              </ul>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={6} className="my-3">
              <h5>We’re Here To Help!</h5>
              <p>
                Tell us what you’re looking for and we’ll get you connected to
                the right people.
              </p>
              <Link
                to="/contact-us"
                className="btn btn-outline-primary rounded-pill"
              >
                Contact us
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
        <Container>
          <hr className="m-0" />
          <Row>
            <Col xxl={5} xl={5} lg={5} md={6} className="my-3">
              <p>digitalcybersolution © 2023. All Rights Reserved</p>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={6} className="my-3">
              <ul>
                <li>
                  <Link>Privacy Policy</Link>
                </li>
                <li>
                  <Link>Terms of Service</Link>
                </li>
                <li>
                  <Link>Cookie policy</Link>
                </li>
              </ul>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={6} className="my-3">
              <ul>
                <li>
                  <Link>
                    <svg
                      aria-hidden="true"
                      class="e-font-icon-svg e-fab-facebook-square"
                      viewBox="0 0 448 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path>
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link>
                    <svg
                      aria-hidden="true"
                      class="e-font-icon-svg e-fab-linkedin"
                      viewBox="0 0 448 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link>
                    <svg
                      aria-hidden="true"
                      class="e-font-icon-svg e-fab-twitter-square"
                      viewBox="0 0 448 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z"></path>
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link>
                    <svg
                      aria-hidden="true"
                      class="e-font-icon-svg e-fab-dribbble-square"
                      viewBox="0 0 448 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M90.2 228.2c8.9-42.4 37.4-77.7 75.7-95.7 3.6 4.9 28 38.8 50.7 79-64 17-120.3 16.8-126.4 16.7zM314.6 154c-33.6-29.8-79.3-41.1-122.6-30.6 3.8 5.1 28.6 38.9 51 80 48.6-18.3 69.1-45.9 71.6-49.4zM140.1 364c40.5 31.6 93.3 36.7 137.3 18-2-12-10-53.8-29.2-103.6-55.1 18.8-93.8 56.4-108.1 85.6zm98.8-108.2c-3.4-7.8-7.2-15.5-11.1-23.2C159.6 253 93.4 252.2 87.4 252c0 1.4-.1 2.8-.1 4.2 0 35.1 13.3 67.1 35.1 91.4 22.2-37.9 67.1-77.9 116.5-91.8zm34.9 16.3c17.9 49.1 25.1 89.1 26.5 97.4 30.7-20.7 52.5-53.6 58.6-91.6-4.6-1.5-42.3-12.7-85.1-5.8zm-20.3-48.4c4.8 9.8 8.3 17.8 12 26.8 45.5-5.7 90.7 3.4 95.2 4.4-.3-32.3-11.8-61.9-30.9-85.1-2.9 3.9-25.8 33.2-76.3 53.9zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-64 176c0-88.2-71.8-160-160-160S64 167.8 64 256s71.8 160 160 160 160-71.8 160-160z"></path>
                    </svg>
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}
export default Footer;
