import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function AboutUs(){
    return (
      <section className="about-us">
        <Container>
          <Row className="align-items-end">
            <Col xxl={6} xl={6} lg={7} md={12} className="my-3">
              <div className="web-heading">
                <h5>about us</h5>
                <h1 className="web-heading">Welcome to Digital Cyber Solution</h1>
                <p>
                Welcome to Digital Cyber Solution, a professional platform offering services like document creation and online form fill-up. We hope you enjoy our services. If you have any questions or need more information, please contact us a <a href="mailto:csc@digitalcybersolution.co.in">csc@digitalcybersolution.co.in</a>
                </p>
                <p>About Digital Cyber Solution: Founded on 01/10/2023 by Mr. Piyush Chauhan, we aim to help viewers enhance their skills through our content. With 5 years of experience in document services, we provide quality services in minimal time. Contact us with any inquiries. Thank you for visiting our website.</p>
              </div>
              <div className="timeline-check my-4">
                <ul>
                  <li>
                    <i className="fa fa-circle-check" />
                    <p>Comprehensive Document Services</p>
                  </li>
                  <li>
                    <i className="fa fa-circle-check" />
                    <p>Effortless Online Form Fill-up</p>
                  </li>
                  <li>
                    <i className="fa fa-circle-check" />
                    <p>Quality and Speed</p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={5} md={12} className="text-end mt-3">
              <img
                src="assets/img/portrait-of-cheerful-young-man-using-laptop-and-sm-2021-08-30-02-08-07-utc-1.png"
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      </section>
    );
}
export default  AboutUs;