import React, { useState, useEffect } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

function AppNavBar() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`mynvbar ${scrolled ? "scroll" : ""}`}>
      <Container>
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand href="/"><img  src="assets/img/logo-1.png" alt=""  style={{ width: '50px' }} /></Navbar.Brand>
            <Navbar.Toggle
              className="shadow-none"
              aria-controls="basic-navbar-nav"
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto">
                <Nav.Item>
                  <Nav.Link href="/">Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/about-us">about us</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/our-services">service</Nav.Link>
                </Nav.Item>
              </Nav>
              <div className="contactus">
                <img src="assets/img/rocket.webp" alt="Rocket" />
                <span className="circle1" />
                <span className="circle2" />
                <span className="circle3" />
                <span className="circle4" />
                <span className="circle5" />
                <span className="circle6" />
                <Link to="/contact-us" className="btn btn-lg btn-primary fs-6 pe-5">
                  contact
                </Link>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Container>
    </div>
  );
}
export default AppNavBar;
