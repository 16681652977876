import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function TrustedLogo() {
  const TrustLogo = [
    {
      id: 1,
      src: "assets/img/logo.svg",
    },
    {
      id: 2,
      src: "assets/img/logo.svg",
    },
    {
      id: 3,
      src: "assets/img/logo.svg",
    },
    {
      id: 4,
      src: "assets/img/logo.svg",
    },
    {
      id: 5,
      src: "assets/img/logo.svg",
    },
    {
      id: 6,
      src: "assets/img/logo.svg",
    },
    {
      id: 7,
      src: "assets/img/logo.svg",
    },
    {
      id: 8,
      src: "assets/img/logo.svg",
    },
    {
      id: 9,
      src: "assets/img/logo.svg",
    },
    {
      id: 10,
      src: "assets/img/logo.svg",
    },
    {
      id: 11,
      src: "assets/img/logo.svg",
    },
    {
      id: 12,
      src: "assets/img/logo.svg",
    },
  ];

  return (
    <section className="my-5">
      <Container>
        <div className="clients">
          <h3>India’s fastest growing software for HR</h3>
          <p>
            Trusted by the Industry leaders for managing their day-to-day HR
            functions
          </p>
        </div>
        <div className="clients-custom">
          <Row className="bg-light rounded-3">
            {TrustLogo.map((value, index) => {
              return (
                <Col
                  xxl={2}
                  xl={2}
                  lg={3}
                  md={3}
                  xs={6}
                  key={index}
                  className="px-0"
                >
                  <div className="clients-logo">
                    <img src={value.src} alt="Trusted Logo" />
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>
    </section>
  );
}
export default TrustedLogo;
