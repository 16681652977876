import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

function MainServices() {
  const OurMainservices = [
    {
      id: 1,
      TitleName: "GST",
      SubTitle: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus minima laboriosam consequuntur magni illum fugit atque facere libero, earum nihil, officiis autem aut eligendi consequatur laborum reiciendis suscipit? Rem, dolorum.",
      className: "gst",
    },
    {
      id: 2,
      TitleName: "Income",
      SubTitle: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus minima laboriosam consequuntur magni illum fugit atque facere libero, earum nihil, officiis autem aut eligendi consequatur laborum reiciendis suscipit? Rem, dolorum.",
      className: "income",
    },
    {
      id: 3,
      TitleName: "Company registration",
      SubTitle: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus minima laboriosam consequuntur magni illum fugit atque facere libero, earum nihil, officiis autem aut eligendi consequatur laborum reiciendis suscipit? Rem, dolorum.",
      className: "company-create",
    }
  ]

  return (
    <section className="main-services">
      <Container>
        <div className="web-heading">
          <h1>Our main streamline services with expert support.</h1>
          <p>
            At emploi, we offer a wide range of HR and recruitment services
            designed to help your business thrive.
          </p>
        </div>
        <Row className="justify-content-center">
          {OurMainservices.map((value, index) => {
            return(
              <Col lg={4} md={6} key={index} className="my-3 d-flex">
              <Card className={`w-100 ${value.className}`}>
                <Card.Body>
                  <h3>{value.TitleName}</h3>
                  <p>{value.SubTitle}</p>
                </Card.Body>
              </Card>
            </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
}
export default MainServices;
