import React from "react";
import { Container, Carousel, Row, Card, Col } from "react-bootstrap";

function FeedBack() {
  const FeedBackText = [
    {
      id: 1,
      src: "https://images.unsplash.com/photo-1599566219227-2efe0c9b7f5f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2787&q=80",
      FeedBack: "“Lorem At emploi, we offer a wide range of HR and recruitment services designed to help your business thrive.”",
    },
    {
      id: 2,
      src: "https://images.unsplash.com/photo-1599566219227-2efe0c9b7f5f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2787&q=80",
      FeedBack: "“Lorem At emploi, we offer a wide range of HR and recruitment services designed to help your business thrive.”",
    },
    {
      id: 3,
      src: "https://images.unsplash.com/photo-1599566219227-2efe0c9b7f5f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2787&q=80",
      FeedBack: "“Lorem At emploi, we offer a wide range of HR and recruitment services designed to help your business thrive.”",
    },
  ];
  return (
    <section className="feedbacks">
      <Container>
        <div class="web-heading">
          <p className="text-primary">It’s clear that our clients 🖤 HROne</p>
          <h1 class="text-primary">Real Customers, Real Stories</h1>
        </div>
        <Carousel fade>
          {FeedBackText.map((value, index) => {
            return (
              <Carousel.Item key={index}>
                <Row>
                  <Col lg={8} className="mx-auto my-3">
                    <Card>
                      <Card.Body>
                        <img src={value.src} />
                        <h5>{value.FeedBack}</h5>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </Container>
    </section>
  );
}
export default FeedBack;
