import React from "react";
import HomeHeader from "../components/home/HomeHeader";
import TrustedLogo from "../components/home/TrustedLogo";
import AboutUs from "../components/home/AboutUs";
import MainServices from "../components/home/MainServices";
import OurServices from "../components/home/OurServices";
import CallBack from "../components/home/CallBack";
import FeedBack from "../components/home/FeedBack";
import ContactUs from "../components/shared/ContactUs";


function Home() {
  return (
    <React.Fragment>
      <HomeHeader/>
      <TrustedLogo/>
      <AboutUs/>
      <MainServices/>
      <OurServices/>
      <CallBack/>
      <FeedBack/>
      <ContactUs/>
    </React.Fragment>
  );
}

export default Home;
