import "./css/App.css";
import "./css/responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from "./pages/home";
import AppNavBar from "./components/shared/AppNavBar";
import Footer from "./components/shared/Footer";
import { Routes, Route } from "react-router-dom";
import Contactus from "./pages/contact-us";
import AboutUs from "./components/home/AboutUs";
import OurServices from "./components/home/OurServices";
import Dashboard from "./components/home/Dashboard";
import ThankYou from "./pages/thank-you";
import NotFound from "./pages/404-page";
import DynamicContactUs from "./pages/dynamic-contact-us";

function App() {
  return (
    <div className="App">
      <AppNavBar/>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="*" element={<NotFound/>}/>
        <Route path="/about-us" element={<AboutUs />}/>
        <Route path="/our-services" element={<OurServices />}/>
        <Route path="/contact-us" element={<Contactus />}/>
        <Route path="/dashboard" element={<Dashboard/>}/>
        <Route path="/thankyou" element={<ThankYou/>}/>
        <Route path="/service/:id" element={<DynamicContactUs/>} />
      </Routes>
      <ToastContainer/>
      <Footer/>
    </div>
  );
  
}
export default App;
