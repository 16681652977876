import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import TrustedCounter from "./TrustedCounter";
import { Link } from "react-router-dom";

function HomeHeader() {
  return (
    <section className="header">
      <div className="hero-header">
        <Container>
          <Row className="align-items-center">
            <Col
              xxl={7}
              xl={7}
              lg={7}
              md={12}
              className="my-xxl-3 my-md-3 order-md-2 order-lg-1"
            >
              <h1>Welcome to our Website Digital Cyber Solution</h1>
              <p>
                Digital Cyber Solution, is a professional platform where we
                provide Cyber Café services like Making Documents, Online Form
                Fill-up. We hope you like all the services provided by us. If
                you have additional questions or require more information about
                our Website, do not hesitate to Contact through email at
                <a
                  href="mailto:csc@digitalcybersolution.co.in"
                  className="ms-1 text-lowercase"
                >
                  csc@digitalcybersolution.co.in
                </a>
              </p>
              <Link to="/contact-us" className="btn btn-primary btn-lg shadow">
                contact <i className="fa fa-arrow-right ms-1" />
              </Link>
            </Col>
            <Col
              xxl={5}
              xl={5}
              lg={5}
              md={12}
              className="text-lg-end text-center my-xxl-3 my-md-3 order-md-1 order-lg-2"
            >
              <img
                src="assets/img/portrait-of-cheerful-young-man-using-laptop-and-sm-2021-08-30-02-08-07-utc-1.png"
                alt="Header Img"
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <TrustedCounter />
    </section>
  );
}
export default HomeHeader;
